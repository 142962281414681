import {Grid, Container, FormControl, RadioGroup, Radio} from '@mui/material';
import classes from './ShipmentDetail.module.scss'
import ActionBar from '../ui/ActionBar';
import BorderBox from '../ui/BorderBox';
import SelectShipment from './SelectShipment';
import Carrier from '../carriers/Carrier';
import {useState, useEffect} from 'react';
import {Button, Col, Row} from "reactstrap";
import {
  CustomDateTimePicker,
  CustomFormControlLabel,
  CustomIconButton,
  CustomTextField,
  CustomTimePickerForm
} from '../../style/mui/customStyle';
import ShipmentQuoteGrid from './ShipmentQuoteGrid';
import AdvancedReceiving from './AdvancedReceiving';
import DirectShipping from './DirectShipping';
import ShipmentVerfication from './ShipmentVerification';
import ShipmentItems from './ShipmentItems';
import AddShipmentItem from './AddShipmentItem';
import ShipmentExpenses from './ShipmentExpenses';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import { ADDRESSES, FORMATS } from "../../utils/constants";
import { formatDateTimeDayjs } from "../../utils/common";
import { useCreateJobShipmentMutation, useGetJobShipmentQuery, useGetJobShipmentsQuery, useGetClientFacilityAddressQuery, usePrintJobShipmentMutation } from '../../services/authService';
import { enqueueSnackbar } from "notistack";
import messages from "../../utils/messages";
import { useParams } from 'react-router-dom';
import { setShipment } from "../../redux/reducers/shipmentSlice";
import dayjs from 'dayjs';
import ProGrid from './ProGrid';
import LabelInfo from '../ui/LabelInfo';
import PrintShipmentDialog from './PrintShipmentDialog';
import useAuthentication from '../../hooks/useAuthentication';
import { numFloat } from "../../utils/common";
import DeleteShipment from "./DeleteShipment";

const validationSchema = Yup.object({
  quoteAmount: Yup.number().nullable()
    .typeError('Amount must be a number') 
    .min(0, 'Amount must be a positive number'),
  actualAmount: Yup.number().nullable()
  .typeError('Amount must be a number') 
  .min(0, 'Amount must be a positive number'),
  directHandlingRate: Yup.number().nullable()
  .typeError('Handling Rate must be a number') 
  .min(0, 'Handling Rate must be a positive number'),
  advancedReceivingHandlingRate: Yup.number().nullable()
  .typeError('Handling Rate must be a number') 
  .min(0, 'Handling Rate must be a positive number'),
});

function ShipmentDetail() {
  const shipment = useSelector((state) => state.shipmentData.shipment);
  const dispatch = useDispatch();
  const [addShipmentForm, setAddShipmentForm] = useState(false);
  const [isAddShipmentItemOpen, setAddShipmentItemOpen] = useState(false);
  const [shipmentId, setShipmentId] = useState(null);
  const [shipments, setShipments] = useState(null);
  const [facilityAddress, setFacilityAddress] = useState(null);
  const [checkedAdvanceReceiving, setCheckedAdvanceReceiving] = useState(null);
  const [checkedDirect, setCheckedDirect] = useState(null);
  const [printDailog, setPrintDialog] = useState(false);
  
  const { id, sId } = useParams();
  const [createJobShipment, {
    isLoading: isCreateLoading, isSuccess: isCreateSuccess, error: isCreateError
  }] = useCreateJobShipmentMutation();

  const{data: jobShipments, isFetching, isError , refetch: refetchShipments} = useGetJobShipmentsQuery(id && id);
    const { data: clientFacilityAddress, error: clientFacilityAddressError } = useGetClientFacilityAddressQuery(id && id);
    const [printJobShipment, { isLoading: isPrintLoading, isSuccess: isPrintSuccess, error: isPrintError
    }] = usePrintJobShipmentMutation();

  const{data: shipmentData, isFetching: loadingShipment, error: shipmentError, refetch: refetchShipment } = useGetJobShipmentQuery(shipmentId, { skip: shipmentId === null });
  useAuthentication([isError, isCreateError, clientFacilityAddressError, shipmentError]);

  async function fetchShipment() {
    const response = await refetchShipment(shipmentId);
    dispatch(setShipment(response.data));
  }  
  
  useEffect(() =>{
      if (jobShipments && jobShipments?.shipments?.length > 0) {
          let selectedShipmentId = sId ?? jobShipments?.shipments[0]?.id;
          setShipmentId(selectedShipmentId);      
       } 
  },[jobShipments]);

  // set clientFacilityAddress
  useEffect(()=>{
    if(clientFacilityAddress){
      const clientName = clientFacilityAddress?.clientName ? `${clientFacilityAddress.clientName}, ` : '';
      const boothSpace = clientFacilityAddress?.boothSpace || '';
      const facilityName = clientFacilityAddress?.facility?.name || '';
      const showName = clientFacilityAddress?.showName ? `${clientFacilityAddress.showName}` : '';
      const address1 = clientFacilityAddress?.facility?.address?.address1 ? `${clientFacilityAddress?.facility?.address?.address1} `:'';
      const address2 = clientFacilityAddress?.facility?.address?.address2 || '';
      const city = clientFacilityAddress?.facility?.address?.city ? `${clientFacilityAddress.facility.address.city}, ` : '';
      const stateCode = clientFacilityAddress?.facility?.address?.stateCode || '';
      const zip = clientFacilityAddress?.facility?.address?.zip ? `${clientFacilityAddress?.facility?.address?.zip} `: '';
      const countryCode = clientFacilityAddress?.facility?.address?.countryCode || '';
      const phone = clientFacilityAddress?.facility?.address?.phone || '';

      const multiSection = `${clientName}${boothSpace}`;
      const addressLines = `${address1}${address2}`;
      const cityState = `${city}${stateCode}`;
      const zipCountry = `${zip}${countryCode}`;

      // Check if any part of the address exists before adding line breaks and commas
      const addressParts = [
        multiSection,
        showName,
        facilityName,
        addressLines,
        `${cityState} ${zipCountry}`,
        phone,
      ].filter(Boolean);

      const address = addressParts.join('\n');
      setFacilityAddress(address);
    }
  }, [clientFacilityAddress]);

    useEffect(() => {
      if (jobShipments) {          
          setShipments(jobShipments);
          
          if (shipmentId)
            setShipmentId(shipmentId);
      }
  }, [jobShipments]);

    useEffect(() => {
        if (shipmentId) {
          fetchShipment();
        } else {
          dispatch(setShipment(null));
        }
     }, [shipmentId]);
  
    function openAddShipmentItemHandler() {
    setAddShipmentItemOpen(true);
  }
    function closeAddShipmentItemHandler() {
    setAddShipmentItemOpen(false);
  }
  function AddShipmentClickHandler() {
        dispatch(setShipment(null));
        setShipmentId(null);
        setAddShipmentForm(true);

        formik.setFieldValue('isAdvancedReceiving', true);
        formik.setFieldValue('isDirect', false);
        setCheckedAdvanceReceiving(true);
        setCheckedDirect(false);
  }
  function CancelShipmentClickHandler() {
      setAddShipmentForm(false);
      setShipmentId(shipments?.shipments[0]?.id ?? null);
  }
  function handleDirectShippingChange() {
    if (checkedDirect) {
      return;
    }
    formik.setFieldValue('isDirect', true);
    formik.setFieldValue('isAdvancedReceiving', false);

    setCheckedAdvanceReceiving(false);
    setCheckedDirect(true);
  }
  function handleAdvanceReceivingChange() {
    if (checkedAdvanceReceiving) {
      return;
    }
    formik.setFieldValue('isAdvancedReceiving', true);
    formik.setFieldValue('isDirect', false);
    setCheckedAdvanceReceiving(true);
    setCheckedDirect(false);
  }
  async function changeShipmentHandler(value) {
    if (value) {
     setShipmentId(value.id);      
    } 
    else {
      dispatch(setShipment(null));
      setShipmentId(null);
    }
  }
  function handleShipmentDirectionChange(event){
    const selectedValue = event.target.value;
    formik.setFieldValue('shipmentDirection', selectedValue);

    if (!shipment?.shipment){
      const updatedFromAddress = (selectedValue === 'ShipToShow') ? ADDRESSES.cmcAddress : (selectedValue === 'ReturnShipment') ? facilityAddress :'';
      const updatedDirectShippingAddress = (selectedValue === 'ReturnShipment') ? ADDRESSES.cmcAddress : (selectedValue === 'ShipToShow') ? facilityAddress :'';
      formik.setFieldValue('fromAddress', updatedFromAddress);
      formik.setFieldValue('directShippingAddress', updatedDirectShippingAddress);
    }
  }

    const openPrintDialog = async () => {
        
        /*let printShipmentItems = {};
        printShipmentItems.shipmentItems = shipmentData?.shipmentItemMapping.map(item => ({
            noOfPieces: item?.item?.totalQuantity,
            description: item?.item?.description,
            dimensions: `${item?.item?.length}*${item?.item?.width}*${item?.item?.height}`,
            weight: item?.item?.weight
        }));
        printShipmentItems.shipmentId = shipmentData?.shipment?.id;
        
        const response = await printJobShipment(printShipmentItems);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, `${shipment?.shipment?.jobId}_Shipment.pdf`);*/
        setPrintDialog(true);
    }

    const handleMessages = () => {
        if (isPrintSuccess) {
            enqueueSnackbar(`${messages.success.printShipment}`, { variant: 'success' });
        }
        if (isPrintError) {
            enqueueSnackbar(`${messages.error.prefix} ${isPrintError?.data[0]?.errorMessage}`, { variant: 'error' });
        }
    };

    useEffect(() => {
        if (isPrintSuccess || isPrintError) {
            handleMessages();
        }
        // eslint-disable-next-line
    }, [isPrintSuccess, isPrintError]);

  function closePrintDialog(){
    setPrintDialog(false);
  }

  const formik = useFormik({
    initialValues: {
      jobId: id,      
      shipExhibitToShow: {
        jobId: id,
        type: "ShipExhibitToShow"
      },
      verifyExhibitAtShow: {
        jobId: id,
        type: "VerifyExhibitAtShow"
      },
      bookShipment: {
        jobId: id,
        type: "BookShipment"
      },
    },
    validationSchema,
      onSubmit: async (values) => {
     
        // Create a request object with the common parameters
      if (addShipmentForm === false && !shipmentId) return false;

      const requestObject = {
        jobId: values.jobId
      }
      if (values.freightCarrierId)
        requestObject.freightCarrierId = values.freightCarrierId;
      if (values.fromAddress)
        requestObject.fromAddress = values.fromAddress;
      if (values.notes)
        requestObject.notes = values.notes;
      if (values.shipDate)
        requestObject.shipDate = values.shipDate;
      if (values.quoteAmount)
        requestObject.quoteAmount = values.quoteAmount;
      if (values.actualAmount)
        requestObject.actualAmount = values.actualAmount;
      if (values.proNumber)
        requestObject.proNumber = values.proNumber;
      if (values.pickupDate)
        requestObject.pickupDate = values.pickupDate;
      if (values.driverCheckInDeadline)
        requestObject.driverCheckInDeadline = values.driverCheckInDeadline;
      if (values.deliveryDate)
        requestObject.deliveryDate = values.deliveryDate;
      if (values.shipmentDirection)
        requestObject.shipmentDirection = values.shipmentDirection;
        
      requestObject.isDirect = values.isDirect;
      if(values.directShippingAddress)
        requestObject.directShippingAddress = values.directShippingAddress;
      if(values.directShippingReadyDate)
        requestObject.directShippingReadyDate = values.directShippingReadyDate;
      if(values.directShippingDeadline)
        requestObject.directShippingDeadline = values.directShippingDeadline;
      if(values.directHandlingRate)
        requestObject.directHandlingRate = values.directHandlingRate;
      
      requestObject.isAdvancedReceiving = values.isAdvancedReceiving;
      if(values.advancedReceivingAddress)
        requestObject.advancedReceivingAddress = values.advancedReceivingAddress;
      if (values.advancedReceivingReadyDate)
        requestObject.advancedReceivingReadyDate = values.advancedReceivingReadyDate;
      if (values.advancedReceivingDeadline)
        requestObject.advancedReceivingDeadline = values.advancedReceivingDeadline;
      if (values.advancedReceivingHandlingRate)
        requestObject.advancedReceivingHandlingRate = values.advancedReceivingHandlingRate;
    
      if (shipment?.shipment?.id)
        requestObject.shipmentId = shipment?.shipment?.id;

      requestObject.shipExhibitToShow = {
        jobId: values.shipExhibitToShow.jobId,
        type: values.shipExhibitToShow.type
      };
      if(shipment?.shipment?.shipExhibitToShow?.id)
        requestObject.shipExhibitToShow.id = shipment?.shipment?.shipExhibitToShow?.id;
      if(values.shipExhibitToShow.assignedToId)
        requestObject.shipExhibitToShow.assignedToId = values.shipExhibitToShow.assignedToId;
      if (values.shipExhibitToShow.deadline)
        requestObject.shipExhibitToShow.deadline =  values.shipExhibitToShow.deadline;
      if (values.shipExhibitToShow.status)
        requestObject.shipExhibitToShow.status = values.shipExhibitToShow.status;
      // Conditionally add verifyExhibitAtShow if it has values
      requestObject.verifyExhibitAtShow = {
        jobId: values.verifyExhibitAtShow.jobId,
        type: values.verifyExhibitAtShow.type
      };
      if(shipment?.shipment?.verifyExhibitAtShow?.id)
        requestObject.verifyExhibitAtShow.id = shipment?.shipment?.verifyExhibitAtShow?.id;

      if(values.verifyExhibitAtShow.assignedToId)
        requestObject.verifyExhibitAtShow.assignedToId = values.verifyExhibitAtShow.assignedToId;
      
      if (values.verifyExhibitAtShow.deadline)
        requestObject.verifyExhibitAtShow.deadline =  values.verifyExhibitAtShow.deadline;
      
      if (values.verifyExhibitAtShow.status)
              requestObject.verifyExhibitAtShow.status = values.verifyExhibitAtShow.status;



          // Conditionally add bookatshipment if it has values
          requestObject.bookShipment = {
              jobId: values.bookShipment.jobId,
              type: values.bookShipment.type
          };
          if (shipment?.shipment?.bookShipment?.id)
              requestObject.bookShipment.id = shipment?.shipment?.bookShipment?.id;

          if (values.bookShipment.assignedToId)
              requestObject.bookShipment.assignedToId = values.bookShipment.assignedToId;

          if (values.bookShipment.deadline)
              requestObject.bookShipment.deadline = values.bookShipment.deadline;

          if (values.bookShipment.status)
              requestObject.bookShipment.status = values.bookShipment.status;

       
      const response = await createJobShipment(requestObject);
        if (response.data > 0) {
            let createdShiptmentId = response.data;
            setAddShipmentForm(false);
            const refreshresponse = await refetchShipments();
            setShipments(refreshresponse.data);
            if (!requestObject.shipmentId) 
                setShipmentId(createdShiptmentId); ///*response.data.shipments[response.data.shipments.length - 1].id*/
        
            const responseShipment = await refetchShipment(createdShiptmentId); //response.data.shipments[response.data.shipments.length - 1].id
            dispatch(setShipment(responseShipment.data));
            enqueueSnackbar(`${messages.success.createShipment}`, { variant: 'success' });
        } else {
            enqueueSnackbar(`${messages.error.prefix} ${response.data ? response.data[0]?.errorMessage : messages.error.generic }`, { variant: 'error' });
        }
    }
  });
  
    useEffect(() => {

        if (shipment?.ItemsChanged === true) return;  // this object is added to stop refreshing shipment details other than items
        
        if (shipment) {
            ['freightCarrierId','fromAddress','shipmentDirection','notes','shipDate','quoteAmount','actualAmount','proNumber','pickupDate','driverCheckInDeadline','deliveryDate', 'isDirect', 'directShippingAddress', 'directHandlingRate','directShippingReadyDate', 'directShippingDeadline', 'isAdvancedReceiving', 'advancedReceivingAddress', 'advancedReceivingHandlingRate', 'advancedReceivingReadyDate', 'advancedReceivingDeadline'].forEach(fieldName => {
                
            let fieldValue = "";
            if (["quoteAmount", "actualAmount", "advancedReceivingHandlingRate", "directHandlingRate"].includes(fieldName)) {
                fieldValue = numFloat(shipment?.shipment?.[fieldName]);
            }
            else {
                fieldValue = shipment?.shipment?.[fieldName];
            }
            
            
            if (fieldValue !== null) {
                formik.setFieldValue(fieldName, fieldValue);
            } else {
                formik.setFieldValue(fieldName, '');
            }
        });
      
      if (shipment?.shipment?.isAdvancedReceiving) {
        setCheckedAdvanceReceiving(true);
      } else {
        setCheckedAdvanceReceiving(false);
      }
      if (shipment?.shipment?.isDirect) {
        setCheckedDirect(true);
      } else {
        setCheckedDirect(false);
      }
      
      // if (shipment?.verifyExhibitAtShow) {
        if (shipment.shipExhibitToShow?.assignedToId) {
            formik.setFieldValue("shipExhibitToShow.assignedToId", shipment.shipExhibitToShow.assignedToId);
        }
        else {
            formik.setFieldValue("shipExhibitToShow.assignedToId", null);
        }

        if (shipment.shipExhibitToShow?.deadline){
          formik.setFieldValue("shipExhibitToShow.deadline", shipment.shipExhibitToShow.deadline);
        }
        else {
            formik.setFieldValue("shipExhibitToShow.deadline", null);
        }

        if (shipment.shipExhibitToShow?.status){
          formik.setFieldValue("shipExhibitToShow.status", shipment.shipExhibitToShow.status);
        }
        else {
            formik.setFieldValue("shipExhibitToShow.status", null);
        }

        if (shipment.shipExhibitToShow?.lastModifiedDate){
          formik.setFieldValue("shipExhibitToShow.lastModifiedDate", shipment.shipExhibitToShow.lastModifiedDate);
        }
        else {
            formik.setFieldValue("shipExhibitToShow.lastModifiedDate", null);
        }

      // }
      // if (shipment?.verifyExhibitAtShow) {
        if (shipment.verifyExhibitAtShow?.assignedToId){
          formik.setFieldValue("verifyExhibitAtShow.assignedToId", shipment.verifyExhibitAtShow.assignedToId);
        }
        else {
            formik.setFieldValue("verifyExhibitAtShow.assignedToId", null);
        }

        if (shipment.verifyExhibitAtShow?.deadline){
          formik.setFieldValue("verifyExhibitAtShow.deadline", shipment.verifyExhibitAtShow.deadline);
        }
        else {
            formik.setFieldValue("verifyExhibitAtShow.deadline", null);
        }

        if (shipment.verifyExhibitAtShow?.status){
          formik.setFieldValue("verifyExhibitAtShow.status", shipment.verifyExhibitAtShow.status);
        }
        else {
            formik.setFieldValue("verifyExhibitAtShow.status", null);
        }

        if (shipment.verifyExhibitAtShow?.lastModifiedDate){
          formik.setFieldValue("verifyExhibitAtShow.lastModifiedDate", shipment.verifyExhibitAtShow.lastModifiedDate);
        }
        else {
            formik.setFieldValue("verifyExhibitAtShow.lastModifiedDate", null);
        }
            // }
            
            // BookShipment
            if (shipment.bookShipment?.assignedToId) {
                formik.setFieldValue("bookShipment.assignedToId", shipment.bookShipment.assignedToId);
            }
            else {
                formik.setFieldValue("bookShipment.assignedToId", null);
            }

            if (shipment.bookShipment?.deadline) {
                formik.setFieldValue("bookShipment.deadline", shipment.bookShipment.deadline);
            }
            else {
                formik.setFieldValue("bookShipment.deadline", null);
            }

            if (shipment.bookShipment?.status) {
                formik.setFieldValue("bookShipment.status", shipment.bookShipment.status);
            }
            else {
                formik.setFieldValue("bookShipment.status", null);
            }

            if (shipment.bookShipment?.lastModifiedDate) {
                formik.setFieldValue("bookShipment.lastModifiedDate", shipment.bookShipment.lastModifiedDate);
            }
            else {
                formik.setFieldValue("bookShipment.lastModifiedDate", null);
            }

         

    } else {
      setCheckedAdvanceReceiving(false);
      setCheckedDirect(false);
            ['freightCarrierId', 'fromAddress', 'shipmentDirection', 'notes', 'shipDate', 'quoteAmount', 'actualAmount', 'proNumber', 'pickupDate', 'driverCheckInDeadline', 'deliveryDate', 'isDirect', 'directShippingAddress', 'directHandlingRate', 'directShippingReadyDate', 'directShippingDeadline', 'isAdvancedReceiving', 'advancedReceivingAddress', 'advancedReceivingHandlingRate', 'advancedReceivingReadyDate', 'advancedReceivingDeadline', 'shipExhibitToShow.assignedToId', 'shipExhibitToShow.deadline', 'shipExhibitToShow.status', 'shipExhibitToShow.lastModifiedDate', 'verifyExhibitAtShow.assignedToId', 'verifyExhibitAtShow.deadline', 'verifyExhibitAtShow.status', 'verifyExhibitAtShow.lastModifiedDate','bookShipment.assignedToId', 'bookShipment.deadline', 'bookShipment.status', 'bookShipment.lastModifiedDate'].forEach(fieldName => {
        formik.setFieldValue(fieldName, '');
      });
      
      }
      
  }, [shipment]);

  useEffect(()=>{
    if(addShipmentForm){
        handleDirectShippingChange();
      // set this value for Ship to show
      formik.setFieldValue('shipmentDirection', 'ShipToShow');
      formik.setFieldValue('fromAddress', ADDRESSES.cmcAddress); 
      formik.setFieldValue('directShippingAddress', shipment?.shipment?.clientAddress ?  shipment?.shipment?.clientAddress : facilityAddress);
    }
  }, [addShipmentForm]);

  return (
    <Container className={
        `${
          classes.container
        } py-3 p-md-4`
      }
      style={
        {maxWidth: "sm"}
    }>
      <form onSubmit={
        formik.handleSubmit
      }>
        


        <Row className="py-3 py-md-4 ">
            <Col>
              <LabelInfo label="CMC Job Number" info={id} />
            </Col>
            <Col>
            {shipment ? 
              <ActionBar /*onCancel={'/tasklist'} */ loadingSave={isCreateLoading} onSave={formik.handleSubmit} onPrint={openPrintDialog}></ActionBar>
              :
              <ActionBar onCancel={'/tasklist'} loadingSave={isCreateLoading} onSave={formik.handleSubmit}></ActionBar>
            }
            </Col>
        </Row>

        {
          printDailog && 
          <PrintShipmentDialog  
            onCancel={closePrintDialog}
            isOpen={printDailog}
          />
        }
        <BorderBox title="Shipments" boxStyle="mb-3">
          <div className="d-flex justify-content-end mb-2">
            {
            addShipmentForm && <Button color="secondary" size="sm" className="ms-2 px-3"
              onClick={CancelShipmentClickHandler}>Cancel Add Shipment</Button>
          } </div>
            {
                !addShipmentForm &&

                <>
                    <div className="d-flex align-items-center">
                        <SelectShipment AddShipmentClickHandler={AddShipmentClickHandler}
                            jobShipments={jobShipments}
                            formik={formik}
                            changeShipmentHandler={changeShipmentHandler}
                        />
                        {
                            shipmentId &&
                            <DeleteShipment setShipmentId={setShipmentId} selectedShipment={shipmentId} />
                        }
                    </div>
                </>                     
            }
          {
          (addShipmentForm || shipment) &&
            <ShipmentQuoteGrid formik={formik} />
          }
          { shipment &&  <ProGrid /> }
          {
          (addShipmentForm || shipment) && <div>
            <FormControl>
              <h3 className={`lh-sm mt-2 ${classes.title}`}>Shipment Type</h3>
              <RadioGroup row name="shipmentDirection" value={formik.values.shipmentDirection} onChange={handleShipmentDirectionChange} >
                <CustomFormControlLabel value="ShipToShow" control={<Radio checked={"ShipToShow" == formik.values.shipmentDirection}  size="small" disabled={shipment ? true : false} />} label="Ship to Show" />
                <CustomFormControlLabel value="ReturnShipment" control={<Radio checked={"ReturnShipment" == formik.values.shipmentDirection} size="small" disabled={shipment ? true : false} />} label="Return Shipment" />
              </RadioGroup>
            </FormControl>
            <Carrier formik={formik} fieldName="freightCarrierId" carrierId={formik.values.freightCarrierId} />
            <h3 className={ `lh-sm mt-2 ${classes.title}`}>Ship From Address</h3>
            <CustomTextField name="fromAddress" fullWidth multiline rows={5} value={formik.values.fromAddress} onChange={formik.handleChange} />

            <AdvancedReceiving handleAdvanceReceivingChange={handleAdvanceReceivingChange}
              formik={formik}
              checkedAdvanceReceiving={checkedAdvanceReceiving}
            />

            <DirectShipping handleDirectShippingChange={handleDirectShippingChange}
              formik={formik}  
              checkedDirect={checkedDirect}
            />

            <Grid container spacing={2} className='mb-2'>
              <Grid item  xs={6}>
                <h3 className={`lh-sm mt-2 ${classes.title}`}>Pickup Date</h3>
                <CustomTextField name='pickupDate' value={ formik.values.pickupDate} onChange={formik.handleChange} 
                  fullWidth size={"small"} 
                />
              </Grid>
              <Grid item xs={6}>
                <h3 className={`lh-sm mt-2 ${classes.title}`}>Driver Check-in Deadline</h3>
                <CustomIconButton className="table-cell-picker">
                  <CustomTimePickerForm name="driverCheckInDeadline"
                  value={ formik.values.driverCheckInDeadline ? dayjs(`2023-09-28T${formik.values.driverCheckInDeadline}`) :  null}
                  onChange={(newValue) =>{ formik.setFieldValue('driverCheckInDeadline',formatDateTimeDayjs(newValue, FORMATS.time)) } }
                  fullWidth size={"small"} />
                </CustomIconButton>
              </Grid>
            </Grid>
            <Grid container spacing={2} className='mb-2'>
              <Grid item xs={6}>
                <h3 className={`lh-sm mt-2 ${classes.title}`}>Delivery Date</h3>
                <CustomTextField name="deliveryDate"
                  value={formik.values.deliveryDate} onChange={formik.handleChange} 
                  fullWidth size={"small"} />  
              </Grid>
            </Grid>
          </div>
        } </BorderBox>
        {
        (addShipmentForm || shipment) && 
          <BorderBox title="Shipment Verification" boxStyle="mb-3">
            <ShipmentVerfication formik={formik} />
          </BorderBox>
        }
        { shipment && 
          <BorderBox title="Shipment Details" boxStyle="mb-3">
            <ShipmentItems openAddShipmentItemHandler={openAddShipmentItemHandler}
                          showAddItem={true}
                          showManualAdd={false}
              showAction={false} />
            <AddShipmentItem isOpen={isAddShipmentItemOpen}
                onClose={closeAddShipmentItemHandler}
                shipmentId={shipment && shipment?.shipment?.id}
                shipmentData={shipmentData}
            />
            <h3 className={`lh-sm mt-3 ${classes.title}`}>Notes</h3>
            <CustomTextField fullWidth multiline rows={3} className='mb-2'id="notes" name='notes'
              value={formik.values.notes}
              onChange={formik.handleChange}
              />
            <ShipmentExpenses />
          </BorderBox>
        }
        <ActionBar onCancel={'/tasklist'} loadingSave={isCreateLoading} onSave={formik.handleSubmit} />
      </form>
    </Container>
  )
}

export default ShipmentDetail;
